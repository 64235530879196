<template>
  <div class="in-product d-block h-100 w-100 pt-sm-0 pt-2 px-sm-0 px-2 pb-4">
    <div
      v-if="product"
      class="discounted-products-header d-flex justify-content-between mb-3"
    >
      <div class="mr-2 ">
        <svg
          width="13"
          height="13"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.9185 14.3201C17.6595 14.5711 17.5405 14.9341 17.5995 15.2901L18.4885 20.2101C18.5635 20.6271 18.3875 21.0491 18.0385 21.2901C17.6965 21.5401 17.2415 21.5701 16.8685 21.3701L12.4395 19.0601C12.2855 18.9781 12.1145 18.9341 11.9395 18.9291H11.6685C11.5745 18.9431 11.4825 18.9731 11.3985 19.0191L6.96851 21.3401C6.74951 21.4501 6.50151 21.4891 6.25851 21.4501C5.66651 21.3381 5.27151 20.7741 5.36851 20.1791L6.25851 15.2591C6.31751 14.9001 6.19851 14.5351 5.93951 14.2801L2.32851 10.7801C2.02651 10.4871 1.92151 10.0471 2.05951 9.65011C2.19351 9.25411 2.53551 8.96511 2.94851 8.90011L7.91851 8.17911C8.29651 8.14011 8.62851 7.91011 8.79851 7.57011L10.9885 3.08011C11.0405 2.98011 11.1075 2.88811 11.1885 2.81011L11.2785 2.74011C11.3255 2.68811 11.3795 2.64511 11.4395 2.61011L11.5485 2.57011L11.7185 2.50011H12.1395C12.5155 2.53911 12.8465 2.76411 13.0195 3.10011L15.2385 7.57011C15.3985 7.89711 15.7095 8.12411 16.0685 8.17911L21.0385 8.90011C21.4585 8.96011 21.8095 9.25011 21.9485 9.65011C22.0795 10.0511 21.9665 10.4911 21.6585 10.7801L17.9185 14.3201Z"
            fill="#999"
          />
        </svg>
        <span class="fontsize11 text-color-999 pr-1">{{ product.rating }}</span>
      </div>
      <div
        v-if="
          product.hasVarietyAvailable &&
            product.hasVarietyAvailable.discountPercent != 0
        "
        class="discount bg-color-red text-white fontsize12"
      >
        <div class="mb-n1 font-weight-bold" style="font-size: 17px">
          {{ product.hasVarietyAvailable.discountPercent }}
        </div>
        <div>%</div>
      </div>
    </div>
    <div
      v-if="product"
      class="discounted-products-img px-0 mb-3 d-flex flex-column justify-content-center"
    >
      <a @click="(e) => goTo(e, url)" :href="url" target="_blank">
        <img
          :class="product.hasVarietyAvailable != null ? 'product-img' : ''"
          :src="product.image"
          :alt="product.title"
        />
        <img
          v-if="product.hasVarietyAvailable != null"
          class="product-img-hover"
          :src="product.hasVarietyAvailable.image"
          :alt="product.title"
        />
      </a>
      <div
        v-if="comparable"
        @click="$store.commit('toggleCompare', { product, $root })"
        class="compare-btn"
        :selected="isComparing"
      >
        مقایسه <input :ok="isComparing" type="checkbox" disabled />
      </div>
      <flip-countdown v-if="endDate" :deadline="endDate"></flip-countdown>
    </div>
    <div
      v-else
      class="discounted-products-img px-sm-4 px-0 mb-3 position-relative"
    >
      <b-skeleton-img></b-skeleton-img>
    </div>
    <a
      @click="(e) => goTo(e, url)"
      :href="url"
      target="_blank"
      v-if="product"
      class="text-color-444 fontsize13 weight-bold mx-3 title"
    >
      {{ product.title.substring(0, 40) }}
      {{ product.title.length > 40 ? "..." : "" }}
    </a>
    <b-skeleton v-if="!product" class="mx-4"></b-skeleton>
    <b-skeleton v-if="!product" class="mx-4"></b-skeleton>
    <div
      v-if="
        product &&
          product.hasVarietyAvailable != null &&
          product.check_status == 'available'
      "
      class="mx-1 mx-sm-3"
    >
      <!-- <span class="fontsize12 text-color-666 pl-sm-1">قیمت:</span> -->
      <span
        class="line-through fontsize10 text-color-a5 pr-1"
        v-if="product.hasVarietyAvailable.discountPercent != 0"
      >
        {{ product.hasVarietyAvailable.price | price }}
      </span>
      <span
        class="fontsize15 text-color-fa3466 weight-bold pr-sm-1"
        v-if="product.hasVarietyAvailable.discountPercent != 0"
      >
        {{ product.hasVarietyAvailable.discountPrice | price }}
      </span>
      <span class="fontsize15 text-color-fa3466 weight-bold pr-sm-1" v-else>
        {{ product.hasVarietyAvailable.price | price }}
      </span>
      <span class="fontsize10 text-color-a5">تومان</span>
    </div>
    <div v-else-if="product && product.check_status == 'unavailable'">
      <span class="fontsize14 text-color-fa3466 font-weight-bold">
        ناموجود
      </span>
    </div>
    <div v-else-if="product && product.check_status == 'soon'">
      <span class="fontsize14 text-color-a5 font-weight-bold">
        به زودی
      </span>
    </div>
    <div
      v-if="product"
      class="discounted-products-buttons d-flex justify-content-center"
    >
      <button
        @click="favoriteItem()"
        :disabled="disabledFavorite"
        class="heart-button rounded-circle px-2 py-1 ml-2 "
        :class="[
          product.favorite ? 'bg-color-red' : 'bg-color-e7',
          disabledFavorite ? 'bg-color-themeBlue' : '',
        ]"
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8498 2.50071C16.4808 2.50071 17.1108 2.58971 17.7098 2.79071C21.4008 3.99071 22.7308 8.04071 21.6198 11.5807C20.9898 13.3897 19.9598 15.0407 18.6108 16.3897C16.6798 18.2597 14.5608 19.9197 12.2798 21.3497L12.0298 21.5007L11.7698 21.3397C9.4808 19.9197 7.3498 18.2597 5.4008 16.3797C4.0608 15.0307 3.0298 13.3897 2.3898 11.5807C1.2598 8.04071 2.5898 3.99071 6.3208 2.76971C6.6108 2.66971 6.9098 2.59971 7.2098 2.56071H7.3298C7.6108 2.51971 7.8898 2.50071 8.1698 2.50071H8.2798C8.9098 2.51971 9.5198 2.62971 10.1108 2.83071H10.1698C10.2098 2.84971 10.2398 2.87071 10.2598 2.88971C10.4808 2.96071 10.6898 3.04071 10.8898 3.15071L11.2698 3.32071C11.3616 3.36968 11.4647 3.44451 11.5538 3.50918C11.6102 3.55015 11.661 3.58705 11.6998 3.61071C11.7161 3.62034 11.7327 3.63002 11.7494 3.63978C11.8352 3.68983 11.9245 3.74197 11.9998 3.79971C13.1108 2.95071 14.4598 2.49071 15.8498 2.50071ZM18.5098 9.70071C18.9198 9.68971 19.2698 9.36071 19.2998 8.93971V8.82071C19.3298 7.41971 18.4808 6.15071 17.1898 5.66071C16.7798 5.51971 16.3298 5.74071 16.1798 6.16071C16.0398 6.58071 16.2598 7.04071 16.6798 7.18971C17.3208 7.42971 17.7498 8.06071 17.7498 8.75971V8.79071C17.7308 9.01971 17.7998 9.24071 17.9398 9.41071C18.0798 9.58071 18.2898 9.67971 18.5098 9.70071Z"
            fill="#fff"
          />
        </svg>
      </button>
      <a @click="(e) => addOrGoto(e, url)" target="_blank">
        <button class="cart-button rounded-circle px-2 pb-1 bg-color-555">
          <svg width="17" height="17" viewBox="0 0 24 24" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
              fill="#fff"
            />
          </svg>
        </button>
      </a>
      <button
        @click="$store.commit('toggleCompare', { product, $root })"
        class="compare-btn-mobile"
        :selected="isComparing"
        v-if="comparable "
      >
        <CompareSvg />
      </button>
    </div>
  </div>
</template>
<script>
import FlipCountdown from "vue2-flip-countdown";
import CompareSvg from "./svgs/CompareSvg";

export default {
  props: {
    product: Object,
    comparable: {
      default: false,
      type: Boolean,
    },
    newTab: {
      default: false,
      type: Boolean,
    },
  },
  inject: ["mediaQueries"],
  components: {
    CompareSvg,
    FlipCountdown,
  },
  data() {
    return {
      disabledFavorite: false,
    };
  },
  computed: {
    url() {
      return this.product
        ? this.$router.resolve({
            name: "product.detail",
            params: { id: this.product.id, slug: this.product.slug },
          }).href
        : "";
    },
    isComparing() {
      return this.$store.state.compares.find((i) => i.id == this.product.id);
    },
    // زمانی که محصول ویژه باشه با تخفیف
    endDate() {
      if (!this.product || !this.product.varieties) {
        return null;
      }
      let selectedDiscount = null;
      this.product.varieties.forEach((v) => {
        if (v.discounts) {
          v.discounts.forEach((d) => {
            selectedDiscount = d;
          });
        }
      });
      if (!selectedDiscount) {
        return null;
      }
      let nowTime = new Date().getTime() ;
      let start_dateTime = new Date(selectedDiscount.start_date).getTime();
      if (nowTime <= start_dateTime) {
        return window
          .moment(selectedDiscount.end_date)
          .format("YYYY/MM/DD HH:mm");
      } else {
        return null;
      }
    },
  },
  methods: {
    cartToAdd() {
      if (this.$store.state.header.user) {
        this.disabled = true;
        this.$axios
          .post(`/api/cart/add/${this.product.hasVarietyAvailable.id}`, {
            number: 1,
            unit: this.product.unit,
          })
          .then((response) => {
            this.$root.success_notification(response.data.message);
            this.$store.commit("header/pushCart", response.data.data);
            // this.$router.push("/cart");
          })
          .catch((error) => {
            console.log(error);
            this.$root.error_notification(error);
          })
          .finally(() => {
            this.disabled = false;
          });
      } else {
        this.$("#loginModal").modal("show");
        this.$store.commit("setPendingCartToAdd", {
          id: this.product.varieties[0].id,
          number: 1,
          unit: this.product.unit,
        });
      }
    },
    favoriteItem() {
      let item = {
        ...this.product,
      };
      this.disabledFavorite = true;
      if (this.$store.state.header.user) {
        this.$axios
          .post("/api/favorites/add/" + item.id)
          .then((response) => {
            item.favorite == false
              ? (this.product.favorite = true)
              : item.favorite == true
              ? (this.product.favorite = false)
              : "";
            this.$root.success_notification(response.data.message);
          })
          .catch((error) => {
            this.$root.error_notification(error);
          })
          .finally(() => {
            this.disabledFavorite = false;
          });
      } else {
        this.$root.warning_notification("ابتدا وارد حساب کاربری خود شوید");
        this.disabledFavorite = false;
        this.show = false;
      }
    },
    goTo(e, url) {
      if (this.newTab) {
        return;
      }
      e.preventDefault();
      this.$router.push(url);
    },
    addOrGoto(e, url) {
      if (this.product.varieties && this.product.varieties.length == 1) {
        this.cartToAdd();
        return;
      }
      window.open(url, "_blank");
    },
  },
};
</script>
<style>
.b-aspect-content {
  margin-left: unset !important;
}

.in-product .b-aspect.d-flex {
  height: 100%;
}
.in-product .flip-card {
  font-size: 1.2rem !important;
}
@media screen and (min-width: 1250px) {
  .in-product .flip-card {
    font-size: 1.4rem !important;
  }
}
@media screen and (min-width: 1000px) {
  .in-product .flip-card {
    font-size: 1.3rem !important;
  }
}
@media screen and (min-width: 700px) {
  .in-product .flip-card {
    font-size: 1.1rem !important;
  }
}
@media screen and (max-width: 500px) {
  .in-product .flip-card {
    font-size: 1rem !important;
  }
  .in-product .flip-clock {
    padding: 0 !important;
  }
}
@media screen and (max-width: 380px) {
  .in-product .flip-card {
    font-size: 0.9rem !important;
  }
}
@media (min-width: 1000px) {
  .in-product .flip-clock__piece {
    margin: 1px !important;
  }

  .in-product .container.flip-clock {
    padding: 0 !important;
    margin-top: 5px;
  }
}
.flip-clock {
  direction: ltr;
}
/** up */
.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
  background: #9995c4 !important;
  color: white !important;
}
/** down */
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__bottom-4digits,
.flip-card__back-bottom-4digits {
  background: #595695 !important;
  color: #f1f1f1 !important;
}
.in-product .flip-clock__slot {
  display: none !important;
}
</style>
<style scoped>
.compare-btn:not([selected]) {
  position: absolute;
  left: 30px;
  bottom: 111px;
  background: white;
  border-radius: 4px;
  padding: 1px 9px;
  border: 1px solid;
  border-color: #7570b3;
  font-size: 13px;

  transition: 200ms all;
  opacity: 0;
  pointer-events: none;
}
.in-product:hover .compare-btn {
  transition: 200ms all;
  opacity: 0.6;
  pointer-events: unset;
}
.compare-btn input {
  transform: translateY(3px);
}
.compare-btn input:hover {
  cursor: pointer;
}
input[type="checkbox"][disabled] {
  outline: 5px solid #ffffff;
  outline-offset: -20px;
}
input[type="checkbox"][disabled][ok] {
  outline: 5px solid var(--color-themeBlue);
  outline-offset: -20px;
}

@media screen and (max-width: 768px) {
  .compare-btn {
    display: none;
  }
}

.compare-btn-mobile {
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  background: #555555;
  border-radius: 50%;
  padding: 7px;
  margin-right: 8px;
}
.compare-btn-mobile[selected] {
  background: var(--color-themeBlue);
}

.title {
  white-space: nowrap;
  max-width: 100%;
  display: block;
  padding-top: 6px;
  overflow: hidden;
}
</style>
