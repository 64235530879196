<template>
  <svg fill="#fff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<g>
			<polygon points="179.456,307.2 0,307.2 0,358.4 179.456,358.4 179.456,435.2 281.6,332.8 179.456,230.4 			"/>
      <polygon points="332.544,153.6 332.544,76.8 230.4,179.2 332.544,281.6 332.544,204.8 512,204.8 512,153.6 			"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>

</template>

<script>
export default {
  name: "CompareSvg"
}
</script>

<style scoped>

</style>
